<script setup>
    import { ref, onMounted, watch } from 'vue'
    import HeaderBanner from '@/components/home/HeaderBanner.vue';
    import { updateBreakpoints, useMq } from "vue3-mq";
    import { useI18n } from 'vue-i18n';

    const myHeaderVideo = ref(null);
    const mq = useMq();

    const { locale } = useI18n();
    let videoUrl = ref(new URL(`/public/assets/videos/es/carspotweb.webm`, import.meta.url).href)

    if(locale.value === 'en') {
        videoUrl = ref(new URL(`/public/assets/videos/en/carspotweb.webm`, import.meta.url).href)
    } else 
    if(locale.value === 'fr') {
        videoUrl = ref(new URL(`/public/assets/videos/fr/carspotweb.webm`, import.meta.url).href)
    } /*else 
    if(locale.value === 'ca') {
        videoUrl = ref(new URL(`/public/assets/videos/ca/carspotweb.webm`, import.meta.url).href)
    } else 
    if(locale.value === 'pt') {
        videoUrl = ref(new URL(`/public/assets/videos/pt/carspotweb.webm`, import.meta.url).href)
    } */


    watch(locale, () => {
        if(locale.value === 'es') {
            videoUrl.value = new URL(`/public/assets/videos/es/carspotweb.webm`, import.meta.url).href
        } else 
        if(locale.value === 'fr') {
            videoUrl.value = new URL(`/public/assets/videos/fr/carspotweb.webm`, import.meta.url).href
        }/*else 
        if(locale.value === 'ca') {
            videoUrl.value = new URL(`/public/assets/videos/ca/carspotweb.webm`, import.meta.url).href
        }  else 
        if(locale.value === 'pt') {
            videoUrl = ref(new URL(`/public/assets/videos/pt/carspotweb.webm`, import.meta.url).href)
        } */ else {
            videoUrl.value = new URL(`/public/assets/videos/en/carspotweb.webm`, import.meta.url).href
        }
    });

    onMounted(() => {
        updateBreakpoints({
            breakpoints: {
                mobile: 600,
                desktop: 1000
            }
        })
        if(myHeaderVideo && myHeaderVideo.value) {
            myHeaderVideo.value.addEventListener("canplay", function () {
            setTimeout(function () {
                myHeaderVideo.value.pause();
            }, 3150);
            setTimeout(function () {
                myHeaderVideo.value.play();
            }, 4000);
            });
        }
    });
</script>
<template>
    <div class="home-header-img">
        <HeaderBanner />
        <div class="home-banner-video-wrapper"  v-if="!mq.mobile || mq.desktop">
            <div class="home-banner-desktop">
                <video 
                    class="home-banner-video-js"
                    id="myVideo"
                    height="580"
                    preload="auto"
                    autoplay muted loop>
                    <source :src=videoUrl type="video/webm">
                </video>
            </div>
        </div>
        <div class="home-banner-video-wrapper-mobile" v-if="mq.mobile || !mq.desktop">
            <div class="container-wrapper-home-header-mobile">
                <div class="container-banner-header-mobile">
                    <div class="home_suite_title">
                        {{ $t('header.suite_digital') }}
                        <span class="home_suite_sub_title primary-text">
                            {{ $t('header.suite_digital_dms') }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .home-header-img {
        width: 100%;
        height: 580px;
        padding-top: 60px;
        img {
            width: 100%;
            height: 580px;
            object-fit: cover;
        }
    }
    .home-banner-video-wrapper {
        padding-top: 60px;
        height: 580px;
        .home-banner-video-js {
            object-fit: fill;
            width: 100%;
            height: 570px;
            object-fit: cover;
            position: absolute;
            left: 0;
            right: 0;
            top: 55px;
            bottom: 0;
            z-index: -1;
        }
    }
    .home-banner-video-wrapper-mobile {
        display: none;
    }
    @media (max-width: 1000px) {
        .home-banner-video-wrapper {
            display: none;
        }
        .home-header-img {
            height: 100vh;
            padding-top: 50px;
        }
        .home-banner-video-wrapper-mobile {
            display: block;
            position: relative;
            height: calc(100vh - 50px);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-image: url("/assets/mobile/home_header_movile.webp");
            .container-wrapper-home-header-mobile {
                position: absolute;
                display: flex;
                justify-content: center;
                width: 100%;
                top: 160px;
                .container-banner-header-mobile {
                    font-size: 2.5rem;
                    line-height: 2.5rem;
                    font-family: 'CircularStd-Black';
                    color: #fff;
                    padding: 10px 35px;
                }
            }
            video {
                object-fit: fill;
                width: 100%;
                height: 100vh;
                object-fit: cover;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -1;
            }
            
        }
    }
</style>