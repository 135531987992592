<script setup>
import { useRouter } from 'vue-router'
import { ref, watch } from 'vue'
import LangSelector from './utils/LangSelector.vue';
import { useI18n } from 'vue-i18n';

const router = useRouter()
const { locale } = useI18n();
let location = ref(locale.value);
let selectedOption = ''
let blogUrl = 'https://blog.cloudactivereception.com/' + location.value

const navigateToRoute = () => {
    router.push(selectedOption);
}
watch(locale, () => {
    location = locale.value;
    blogUrl = 'https://blog.cloudactivereception.com/' + location
});
</script>

<template>
    <footer>
        <div class="container container-fluid">
            <div class="wrapper">
                <div class="inner-wrapper">
                    <div class="right-wrapper">
                        <div class="wrapper-top">
                            <div class="navbar-replica">
                                <router-link :to="`/${location}`" class="navbar-brand d-flex">
                                    <img src="/assets/logo-white.png" />
                                    <div class="navbar-title">cloud active reception</div>
                                </router-link>
                            </div>
                            <div class="contact-wrapper">
                                <div class="inner-wrapper">
                                    <div class="icon-wrapper">
                                        <font-awesome-icon icon="fa-solid fa-phone" />
                                    </div>
                                    <div class="text-wrapper">
                                        +34 93 872 29 99
                                    </div>
                                </div>
                                <div class="inner-wrapper">
                                    <div class="icon-wrapper">
                                        <font-awesome-icon icon="fas-solid fa-envelope" />
                                    </div>
                                    <div class="text-wrapper">
                                        <a href="mailto: info@cloudactivereception.com">
                                            info@cloudactivereception.com
                                        </a>
                                    </div>
                                </div>
                                <div class="inner-wrapper">
                                    <div class="icon-wrapper">
                                        <font-awesome-icon icon="fas-solid fa-location-dot" />
                                    </div>
                                    <div class="text-wrapper">
                                        <a target="_blank" href="https://maps.app.goo.gl/NtLaxnaAyKwXERa17">
                                            C/ d'Agustí Coll, 32, P. 2 <br>08242 Manresa, BCN
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="menu-wrapper">
                            <div class="inner-wrapper">
                                <div class="submenu">
                                    <div class="left-nav navbar-nav">
                                        <router-link :to="`/${location}`" class="nav-item nav-links">
                                            {{ $t('field.start') }}
                                        </router-link>
                                        <router-link :to="`/${location}/functionalities`" class="nav-item nav-links car">
                                            {{ $t('field.car') }}
                                        </router-link>
                                        <router-link :to="`/${location}/roi`" class="nav-item nav-links">
                                            {{ $t('field.roi') }}
                                        </router-link>
                                        <router-link :to="`/${location}/cases`" class="nav-item nav-links">
                                            {{ $t('field.success_stories') }}
                                        </router-link>
                                        <router-link :to="`/${location}/company`" class="nav-item nav-links">
                                            {{ $t('field.company') }}
                                        </router-link>
                                        <router-link :to="`/${location}/partners`" class="nav-item nav-links">
                                            {{ $t('field.partners') }}
                                        </router-link>
                                        <router-link :to="`/${location}/contact`" class="nav-item nav-links">
                                            {{ $t('field.contact_single') }}
                                        </router-link>
                                    </div>
                                    <div class="right-nav navbar-nav">
                                        <ul class="navbar-nav-group">
                                            <li class="nav-item">
                                                <a class="nav-link nav-brand-icon" target="_blank" aria-current="page" href="https://www.linkedin.com/company/cloud-active-reception/">
                                                    <font-awesome-icon icon="fa-brands fa-linkedin-in" />
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link nav-brand-icon" target="_blank" href="https://www.youtube.com/@carcloudactivereception8918">
                                                    <font-awesome-icon icon="fa-brands fa-youtube" />
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link nav-brand-icon" target="_blank" href="https://www.instagram.com/cloudactivereception/">
                                                    <font-awesome-icon icon="fa-brands fa-instagram" />
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link nav-brand-icon link-blog" target="_blank" :href="blogUrl">
                                                    <font-awesome-icon icon="fa-solid fa-blog" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="wrapper-bottom">
                            <div class="footer-rights">
                                &copyCAR. {{ $t('footer.all_right_reserved') }}
                            </div>
                            <div class="policy-wrapper">
                                <div class="policy-item">
                                    <router-link :to="`/${location}/cookies`" @click="closeMenu">
                                        {{ $t('footer.cookies') }}
                                    </router-link>
                                </div>
                                <div class="policy-item">
                                    <router-link :to="`/${location}/legal`" @click="closeMenu">
                                        {{ $t('footer.legal_advise') }}
                                    </router-link>   
                                </div>
                                <div class="policy-item">
                                    <router-link :to="`/${location}/privacy`" @click="closeMenu">
                                        {{ $t('footer.privacy_policy') }}
                                    </router-link>
                                </div>
                                <div class="policy-item">
                                    <router-link :to="`/${location}/privacy-security`" @click="closeMenu">
                                        {{ $t('footer.security_policy') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="policy-wrapper-select">
                                <select v-model="selectedOption" @change="navigateToRoute">
                                    <option value="" selected disabled>{{ $t('footer.legal_policy') }}</option>
                                    <option value="/cookies">{{ $t('footer.cookies') }}</option>
                                    <option value="/legal">{{ $t('footer.legal_advise') }}</option>
                                    <option value="/privacy">{{ $t('footer.privacy_policy') }}</option>
                                    <option value="/privacy-security">{{ $t('footer.security_policy') }}</option>
                                </select>
                            </div>
                            <div class="lang-wrapper">
                                <LangSelector />
                            </div>
                        </div>
                        <div class="left-footer-develop">
                            <div class="footer-develop">
                                <div class="footer-develop-text">
                                    {{ $t('footer.developed_by') }}
                                </div>
                                <div class="footer-develop-wrapper">
                                    <a href="https://www.inforserveis.es/" target="_blank">
                                        <img src="/assets/inforserveis_footer.svg" />
                                    </a>
                                </div>
                                <div class="footer-certificate">
                                    <img src="/assets/certificat.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<style lang="scss" scoped>
footer {
    background-color: black;
    color: white;
    padding: 80px 0px 50px;
    margin-right: 0px;
    a {
        color: white;
        text-decoration: none;
    }
    .wrapper-top {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        .navbar-brand {
            img {
                width: 70px;
            }
            .navbar-title {
                font-size: 20px;
                font-weight: bold;
                padding-left: 10px;
                padding-top: 15px;
            }
        }
    }
    .inner-wrapper {
        display: flex;
        flex-wrap: inherit;
        align-items: start;
        .contact-wrapper {
            display: flex;
        }
        .right-wrapper {
            width: 100%;
            .contact-wrapper {
                display: flex;
                justify-content: flex-end;
                min-width: 600px;
                .inner-wrapper {
                    padding: 0 15px;
                    display: block;
                    text-align: center;
                    font-size: 14px;
                    .text-wrapper {
                        padding-top: 10px;
                    }
                }
            }
        }
        .menu-wrapper {
            padding-top: 220px;
            .inner-wrapper {
                display: block;
                .submenu {
                    display: flex;
                    justify-content: space-between;
                    .navbar-nav {
                        font-size: 14px;
                        display: flex;
                        flex-direction: row;
                        &.right-nav {
                            justify-items: end;
                        }
                        &.left-nav {
                            a:first-child {
                                padding-left: 0;
                                padding-right: 15px;
                            }
                            .car {
                                text-transform: uppercase;
                            }
                        }
                        .navbar-nav-group {
                            list-style: none;
                            padding-right: 5px;
                            padding-left: 0;
                            margin-bottom: 0;
                            display: flex;
                            justify-content: end;
                            width: 100%;
                        }
                        .nav-item .nav-brand-icon {
                            padding: 0 15px;
                            &:last-child {
                                padding-right: 0;
                            }
                            &:hover {
                                animation: pulse 1s 1;
                                animation-timing-function: linear;     
                            }
                        }
                        a.nav-item.nav-links {
                            padding: 5px 0px 0px 15px;
                            color: white;
                            text-decoration: none;
                            position: relative;
                            display: block;
                            transition: 0.5s;
                            transition: white 100ms linear;
                            padding-bottom: 9px;
                            &:first-child {
                                padding-left: 0;
                                &::before {
                                    left: 0px;
                                } 
                            }
                            
                            &::before {
                                position: absolute;
                                display: block;
                                left: 15px;
                                height: 1px;
                                bottom: 0.5rem;
                                content: "";
                                background: white;
                                -webkit-transition: transform 300ms ease;
                                -moz-transition: transform 300ms ease;
                                transition: transform 300ms ease;
                                -webkit-transform-origin: 50% 50%;
                                -moz-transform-origin: 50% 50%;
                                -ms-transform-origin: 50% 50%;
                                transform-origin: 50% 50%;
                                -webkit-transform: scaleX(0);
                                -moz-transform: scaleX(0);
                                -ms-transform: scaleX(0);
                                transform: scaleX(0);
                                width: 20px;
                            }            
                            &:hover::before {
                                transform: scaleX(1);
                                transform-origin: left;
                            }
                            
                        }
                        a:last-child {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }
    @keyframes pulse {
        0% { transform: scale(1); }
        50% { transform: scale(1.3) }
        100% { transform: scale(1); }
    }
    .wrapper-bottom {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        .footer-rights {
            color: #808080;
            font-size: 12px;
        }
        .lang-wrapper {
            display: flex;
            font-size: 12px;

            position: relative;
            select {
                border: none;
                background-color: transparent;
                color: white;
            }
        }
        .policy-wrapper {
            display: flex;
            font-size: 12px;
            padding-left: 50px;
            .policy-item {
                padding-left: 30px;
            }
            :first-child {
                color: #808080;
                padding-left: 0;
            }
        }
        .policy-wrapper-select {
            display: none;
        }
    }
    .footer-develop {
        font-size: 12px;
        padding-top: 15px;
        display: flex;
        .footer-develop-text {
           padding-top: 15px;
        } 
        .footer-develop-wrapper {
            font-size: 20px;
            img {
                width: 100px;
                padding-left: 10px;
                padding-top: 15px;
            }
        } 
        .footer-certificate {
            width: 70px;
            padding-left: 18px;
            img {
                width: 100%;
            }
        }
    }
    .separator {
        border-right: 1px solid white;
        height: 70%;
    }
}

@media (max-width: 991px) {
    footer {
        background-color: black;
        color: white;
        padding: 40px 70px 30px 40px;
        a {
            color: white;
            text-decoration: none;
        }
        .wrapper-top {
            padding-top: 20px;
            display: flex;
            justify-content: space-between;
            .navbar-brand {
                img {
                    width: 50px;
                    height: 30px;
                }
                .navbar-title {
                    font-size: 14px;
                    font-weight: bold;
                    padding-left: 10px;
                    padding-top: 8px;
                }
            }
        }
        .inner-wrapper {
            display: flex;
            flex-wrap: inherit;
            align-items: start;
            .contact-wrapper {
                display: flex;
            }
            .right-wrapper {
                width: 100%;
                .contact-wrapper {
                    display: none;
                }
            }
            .menu-wrapper {
                padding-left: 59%;
                padding-top: 0px;
                .inner-wrapper {
                    display: block;
                    .submenu {
                        display: flex;
                        justify-content: space-between;
                        .navbar-nav {
                            font-size: 14px;
                            display: flex;
                            flex-direction: row;
                            &.right-nav {                            
                                justify-items: end;
                                font-size: 30px;
                                position: relative;
                                top: -30px;
                                left: 20%;
                            }
                            &.left-nav {
                                display: none;
                            }
                            .navbar-nav-group {
                                list-style: none;
                                padding-left: 0;
                                margin-bottom: 0;
                                display: flex;
                                justify-content: end;
                                width: 100%;
                                .nav-item {
                                    padding-left: 20px;
                                }
                            }
                            a {
                                padding: 0px 15px;
                                color: white;
                            }
                            a:last-child {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
        .wrapper-bottom {
            display: flex;
            justify-content: space-between;
            padding-top: 0px;
            padding-bottom: 30px;
            .footer-rights {
                display: flex;
                color: white;
                font-size: 14px;
                position: relative;
                top: 100px;
                left: 60%;
            }
            .lang-wrapper {
                display: flex;
                font-size: 14px;
                position: relative;
                right: 5%;
                .lang-text {
                    display: none;
                }   
            }
            .policy-wrapper {
                display: none;
            }
            .policy-wrapper-select {
                display: flex;
                position: absolute;
                select {
                    font-size: 14px;
                    background: black;
                    color: white;
                    border: none;
                    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
                    width: 40%;
                    margin-right: 50px;
                    padding-top: 2px;
                    padding-bottom: 2px;
                }
            }
        }
        .footer-develop {
            font-size: 14px;
            display: flex;
            .footer-develop-text {
            padding-top: 15px;
            } 
            .footer-develop-wrapper {
                font-size: 20px;
                position: absolute;
                padding-top: 25px;
                img {
                    width: 100px;
                    padding-left: 10px;
                    padding-top: 15px;
                }
            } 
            .footer-certificate {
                width: 70px;
                padding-left: 18px;
                position: relative;
                padding-top: 25px;
                img {
                    width: 100%;
                }
            }
        }
        .separator {
            border-right: 1px solid white;
            height: 70%;
        }
    }
}
@media (max-width: 767px) {
    footer {
        padding: 35px 70px 30px 40px;
        .inner-wrapper {
            display: flex;
            flex-wrap: inherit;
            align-items: start;
            .contact-wrapper {
                display: flex;
            }
            .right-wrapper {
                width: 100%;
                .contact-wrapper {
                    display: none;
                }
            }
            .menu-wrapper {
                padding-left: 59%;
                padding-top: 0px;
                .inner-wrapper {
                    display: block;
                    .submenu {
                        display: flex;
                        justify-content: space-between;
                        .navbar-nav {
                            font-size: 14px;
                            display: flex;
                            flex-direction: row;
                            &.right-nav {                            
                                justify-items: end;
                                font-size: 25px;
                                position: relative;
                                top: -30px;
                                left: 20%;
                            }
                            &.left-nav {
                                display: none;
                            }
                            .navbar-nav-group {
                                list-style: none;
                                padding-left: 0;
                                margin-bottom: 0;
                                display: flex;
                                justify-content: end;
                                width: 100%;
                                .nav-item {
                                    padding-left: 20px;
                                }
                            }
                            a {
                                padding: 0px 15px;
                                color: white;
                            }
                            a:last-child {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
        .wrapper-bottom {
            display: flex;
            justify-content: space-between;
            padding-top: 0px;
            padding-bottom: 30px;
            .footer-rights {
                display: flex;
                color: white;
                font-size: 14px;
                position: relative;
                top: 100px;
                left: 54%;
            }
            .lang-wrapper {
                display: flex;
                font-size: 14px;
                position: relative;
                left: 2%;
                .lang-text {
                    display: none;
                }   
            }
        }
    }
}
@media (max-width: 575px) {
    footer {
        padding: 30px 70px 30px 40px;
        .wrapper-top {
            padding-top: 20px;
            display: flex;
            justify-content: space-between;
            .navbar-brand {
                img {
                    width: 40px;
                    height: 25px;
                }
                .navbar-title {
                    font-size: 12px;
                    font-weight: bold;
                    padding-left: 10px;
                    padding-top: 5px;
                }
            }
        }
        .inner-wrapper {
            display: flex;
            flex-wrap: inherit;
            align-items: start;
            .contact-wrapper {
                display: flex;
            }
            .right-wrapper {
                width: 100%;
                .contact-wrapper {
                    display: none;
                }
            }
            .menu-wrapper {
                padding-left: 59%;
                padding-top: 0px;
                .inner-wrapper {
                    display: block;
                    .submenu {
                        display: flex;
                        justify-content: space-between;
                        .navbar-nav {
                            font-size: 14px;
                            display: flex;
                            flex-direction: row;
                            &.right-nav {                            
                                justify-items: end;
                                font-size: 20px;
                                position: relative;
                                top: -20px;
                                left: 30%;
                            }
                            &.left-nav {
                                display: none;
                            }
                            .navbar-nav-group {
                                list-style: none;
                                padding-left: 0;
                                margin-bottom: 0;
                                display: flex;
                                justify-content: end;
                                width: 100%;
                                .nav-item {
                                    padding-left: 7px;
                                }
                            }
                            a {
                                padding: 0px 15px;
                                color: white;
                            }
                            a:last-child {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
        .wrapper-bottom {
            display: flex;
            justify-content: space-between;
            padding-top: 0px;
            padding-bottom: 30px;
            .footer-rights {
                display: flex;
                color: white;
                font-size: 10px;
                position: relative;
                top: 95px;
                left: 60%;
            }
            .lang-wrapper {
                display: flex;
                font-size: 12px;
                position: absolute;
                left: 64%;
                .lang-text {
                    display: none;
                }   
            }
            .policy-wrapper {
                display: none;
            }
            .policy-wrapper-select {
                display: flex;
                position: absolute;
                select {
                    font-size: 12px;
                    background: black;
                    color: white;
                    border: none;
                    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
                }
            }
        }
        .footer-develop {
            font-size: 10px;
            display: flex;
            .footer-develop-text {
                padding-top: 15px;
            } 
            .footer-develop-wrapper {
                font-size: 20px;
                position: absolute;
                padding-top: 25px;
                img {
                    width: 75px;
                    padding-left: 10px;
                    padding-top: 5px;
                }
            } 
            .footer-certificate {
                width: 70px;
                padding-left: 18px;
                position: relative;
                padding-top: 25px;
                img {
                    width: 70%;
                }
            }
        }
    }
}
@media (max-width: 475px) {
    footer {
        padding: 30px 80px 30px 20px;
        .wrapper-top {
            padding-top: 20px;
            display: flex;
            justify-content: space-between;
            .navbar-brand {
                img {
                    font-size: 2px;
                    width: 40px;
                    height: 25px;
                }
                .navbar-title {
                    font-size: 12px;
                    font-weight: bold;
                    padding-left: 10px;
                    padding-top: 5px;
                }
            }
        }
        .inner-wrapper {
            display: flex;
            flex-wrap: inherit;
            align-items: start;
            .contact-wrapper {
                display: flex;
            }
            .right-wrapper {
                width: 100%;
                .contact-wrapper {
                    display: none;
                }
            }
            .menu-wrapper {
                padding-left: 59%;
                padding-top: 0px;
                .inner-wrapper {
                    display: block;
                    .submenu {
                        display: flex;
                        justify-content: space-between;
                        .navbar-nav {
                            font-size: 14px;
                            display: flex;
                            flex-direction: row;
                            &.right-nav {                            
                                justify-items: end;
                                font-size: 20px;
                                position: relative;
                                top: -20px;
                                left: 30%;
                            }
                            &.left-nav {
                                display: none;
                            }
                            .navbar-nav-group {
                                list-style: none;
                                padding-left: 0;
                                margin-bottom: 0;
                                display: flex;
                                justify-content: end;
                                width: 100%;
                                .nav-item {
                                    padding-left: 4px;
                                }
                            }
                            a {
                                padding: 0px 15px;
                                color: white;
                            }
                            a:last-child {
                                padding-right: 0px;
                            } 
                        }
                    }
                }
            }
        }
        
        .wrapper-bottom {
            display: flex;
            justify-content: space-between;
            padding-top: 0px;
            padding-bottom: 30px;
            .footer-rights {
                display: flex;
                color: white;
                font-size: 10px;
                position: relative;
                top: 95px;
                left: 62%;
            }
        }
    }
}
@media (max-width: 375px) {
    footer {
        padding: 30px 80px 30px 5px;
        .inner-wrapper {
            display: flex;
            flex-wrap: inherit;
            align-items: start;
            .contact-wrapper {
                display: flex;
            }
            .right-wrapper {
                width: 100%;
                .contact-wrapper {
                    display: none;
                }
            }
            .menu-wrapper {
                padding-left: 59%;
                padding-top: 0px;
                .inner-wrapper {
                    display: block;
                    .submenu {
                        display: flex;
                        justify-content: space-between;
                        .navbar-nav {
                            font-size: 14px;
                            display: flex;
                            flex-direction: row;
                            &.right-nav {                            
                                justify-items: end;
                                font-size: 20px;
                                position: relative;
                                top: -20px;
                                left: 60%;
                            }
                            &.left-nav {
                                display: none;
                            }
                            .navbar-nav-group {
                                list-style: none;
                                padding-left: 0;
                                margin-bottom: 0;
                                display: flex;
                                justify-content: end;
                                width: 100%;
                                .nav-item {
                                    padding-left: 4px;
                                }
                            }
                            a {
                                padding: 0px 15px;
                                color: white;
                            }
                            a:last-child {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>