<script setup>
import { useI18n } from 'vue-i18n';
import { ref, watch } from 'vue'

const { locale } = useI18n();
let location = ref(locale.value);

watch(locale, () => {
    location = locale.value;
});
</script>
<template>
    <div class="home-header-banner">
        <div class="container">
            <div class="w-100 banner-wrapper">
                <div class="header-buttons">{{ $t('header.banner') }}</div>
                <div class="header-buttons">
                    <div class="banner-block-body-link header-buttons-wrapper">
                        <router-link  :to="`/${location}/digital_kit`">
                            {{ $t('header.condition_link') }}
                        </router-link>
                    </div>
                    <div class="banner-block-body-link header-buttons-wrapper">
                        <router-link :to="`/${location}/contact`">
                            {{ $t('field.contact') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .home-header-banner {
        height: 50px;
        background-color: rgba(0, 0, 0, .5);
        position: absolute;
        width: 100%;
        color: white;
        display: flex;
        align-items: center;
        top: 55px;
    }
    .banner-wrapper {
        display: flex;
        justify-content: space-between;
    }
    .header-buttons {
        display: flex;
        .header-buttons-wrapper {
            a {
                color: white;
                text-decoration: none;
            }
        }
        :first-child {
            padding-right: 10px;
        }
    }
    .banner-block-body-link {
        a {
            color: white;
            text-decoration: none;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                width: 95%;
                height: 1px;
                border-radius: 4px;
                background-color: white;
                bottom: -2px;
                left: 0;
                transform-origin: right;
                transform: scaleX(0);
                transition: transform .3s ease-in-out;
            }            
            &:hover::before {
                transform-origin: left;
                transform: scaleX(1);
            }
            
        }
        .link__graphic--slide {
            top: -3px;
            stroke-width: 2px;
            transition: transform 0.7s;
            transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
        }
        .link__graphic {
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            fill: none;
            stroke: #000;
            stroke-width: 1px;
        }
    }

    @media (max-width: 1000px) {
        .home-header-banner {
            height: auto;
            z-index: 4;
            .banner-wrapper {
                display: block;
                font-size: .8rem;
                .header-buttons {
                    justify-content: space-between;
                    padding-top: 15px;
                }
            }
        }
    }

</style>